import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Login from './pages/Login';
import Signup from './pages/Signup';
import AdminPage from './pages/AdminPage';
import AdminDashboard from './pages/AdminDashboard';
import UserDashboard from './pages/UserDashboard';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './context/AuthContext';

const App = () => {
    return (
        <Router>
            <AuthProvider>
                <Header />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Signup />} />
                    <Route path="/admin" element={<PrivateRoute element={AdminPage} role="admin" />} />
                    <Route path="/admin-dashboard" element={<PrivateRoute element={AdminDashboard} role="admin" />} />
                    <Route path="/user-dashboard" element={<PrivateRoute element={UserDashboard} role="user" />} />
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default App;
