import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { Navbar, Nav, Button } from 'react-bootstrap';
import logo from '../assets/logo.png'; // Import your logo image

const Header = () => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <Navbar className="custom-navbar" expand="lg">
            <Navbar.Brand as={Link} to="/">
                <img
                    src={logo}
                    height="30"
                    className="d-inline-block align-top"
                    alt="Logo"
                />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                    <Nav.Link as={Link} to="/">Home</Nav.Link>
                    <Nav.Link as={Link} to="/about">About</Nav.Link>
                    <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                </Nav>
                <Nav>
                    {user ? (
                        <>
                            {user.role === 'admin' && (
                                <Nav.Link as={Link} to="/admin-dashboard">Admin Dashboard</Nav.Link>
                            )}
                            {user.role === 'user' && (
                                <Nav.Link as={Link} to="/user-dashboard">User Dashboard</Nav.Link>
                            )}
                            <Button className="custom-button" onClick={handleLogout}>Logout</Button>
                        </>
                    ) : (
                        <>
                            <Nav.Link as={Link} to="/login">Login</Nav.Link>
                            <Nav.Link as={Link} to="/signup">Signup</Nav.Link>
                        </>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default Header;
