import React from 'react';

const About = () => {
    return (
        <div>
            <h2>About RSMIN</h2>
            <p>About page content</p>
        </div>
    );
};

export default About;
