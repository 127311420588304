import React from 'react';

const Contact = () => {
    return (
        <div>
            <h2>Contact RSMIN</h2>
            <p>Contact page content</p>
        </div>
    );
};

export default Contact;
